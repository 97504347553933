import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
// To support Safari/iPad
// https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf.mjs'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker?url'
import { server } from '../../util/data/server'
import { getStrict } from '../../util/web/primitive'
import type { PaperName } from '../paper/name'
import { getPaperBySize } from '../paper/size'
import { usePageRenderResolution } from '../render/resolution'
import { PAGE_ID } from '../state/id'

GlobalWorkerOptions.workerSrc = pdfjsWorker

export type PageImage = {
  id: string
  sources: string[]
  shape: {
    y: number
    x: number
    w: number
    h: number
  }
}
export type PagePdf = {
  // Maybe it's better to use off-screen canvas?
  // But "transfer to image bitmap" would clear the off-screen canvas every time,
  // while we want to copy the canvas content to other places later.
  canvas: HTMLCanvasElement
  width: number
  height: number
  images: PageImage[]
}

type Scale = {
  scale: number
}

export const PAGE_PDF_SCALES = [
  { zoomLevel: 0, scale: 1 },
  { zoomLevel: 2, scale: 4 },
  { zoomLevel: 8, scale: 6 },
]

export const PAPER_PDF_SCALES: Record<PaperName, Scale> = {
  A0: { scale: 4 },
  A1: { scale: 4 },
  A2: { scale: 8 },
  A3: { scale: 8 },
  A4: { scale: 8 },
}

const PAGE_PDF_N_TILES = 8
/**
 * It's intentional that this is a different query than the "page detail" query,
 * even though they use the same endpoint.
 * This is because "get page detail" returns a new "signed url" on each request,
 * which causes caching issues easily.
 * For example, if the queries are the same,
 * then updating the page's scale would unexpectedly cause the PDF to reload.
 */
export function usePagePdf(): UseQueryResult<
  PagePdf,
  // Important: The query fn here does not only get the URL,
  // but also fetches and reads the PDF, so error could be more than API error
  Error
  > {
  const { updateResolution } = usePageRenderResolution()
  return useQuery({
    queryKey: ['pdf'],
    queryFn: async (): Promise<PagePdf> => {
      const { signedURL: url } = await server.getPageDetail(PAGE_ID)
      const pdf = await getDocument({ url, cMapUrl: 'public/cmaps', cMapPacked: true }).promise

      // Our PDF files have only 1 page by design
      const page = await pdf.getPage(1)

      const origin = page.getViewport({ scale: 1 })

      const paper = getPaperBySize({
        min: Math.min(origin.width, origin.height),
        max: Math.max(origin.width, origin.height),
      })

      const scale = PAPER_PDF_SCALES[paper].scale

      updateResolution({ resolution: scale })

      const viewport = page.getViewport({ scale })
      const canvas = document.createElement('canvas')
      canvas.width = viewport.width
      canvas.height = viewport.height

      const context = getStrict(canvas.getContext('2d', { willReadFrequently: true }))
      await page.render({ canvasContext: context, viewport }).promise

      // canvas tiles
      const viewports = PAGE_PDF_SCALES.map(({ scale }) => page.getViewport({ scale }))

      const canvases = PAGE_PDF_SCALES.map((_, idx) => {
        const canvas = document.createElement('canvas')
        canvas.width = viewports[idx].width
        canvas.height = viewports[idx].height
        return canvas
      })

      async function renderCanvas(canvas: HTMLCanvasElement, pageIdx: number) {
        const viewport = viewports[pageIdx]
        const canvasContext = getStrict(canvas.getContext('2d'))
        return page.render({ canvasContext, viewport }).promise
      }

      await Promise.all(canvases.map((canvas, idx) => renderCanvas(canvas, idx)))

      const tmpCanvases = PAGE_PDF_SCALES.map((_, idx) => {
        const canvas = document.createElement('canvas')
        canvas.width = viewports[idx].width / PAGE_PDF_N_TILES
        canvas.height = viewports[idx].height / PAGE_PDF_N_TILES
        return canvas
      })

      const images = []

      const mainViewport = viewports[viewports.length - 1]

      const pdfWidth = mainViewport.width / mainViewport.scale
      const pdfHeight = mainViewport.height / mainViewport.scale

      for (let row = 0; row < PAGE_PDF_N_TILES; row++) {
        for (let col = 0; col < PAGE_PDF_N_TILES; col++) {
          const image: PageImage = {
            id: crypto.randomUUID(),
            sources: [],
            shape: {
              x: col * (pdfWidth / PAGE_PDF_N_TILES),
              y: row * (pdfHeight / PAGE_PDF_N_TILES),
              w: pdfWidth / PAGE_PDF_N_TILES,
              h: pdfHeight / PAGE_PDF_N_TILES,
            },
          }

          for (let idx = 0; idx < PAGE_PDF_SCALES.length; idx++) {
            const tileWidth = viewports[idx].width / PAGE_PDF_N_TILES
            const tileHeight = viewports[idx].height / PAGE_PDF_N_TILES

            const sx = col * tileWidth
            const sy = row * tileHeight

            const canvas = tmpCanvases[idx]
            const context = getStrict(canvas.getContext('2d'))

            context.clearRect(0, 0, tileWidth, tileHeight)
            context.drawImage(canvases[idx], sx, sy, tileWidth, tileHeight, 0, 0, tileWidth, tileHeight)

            image.sources.push(canvas.toDataURL())
          }

          images.push(image)
        }
      }

      // Clean up
      page.cleanup()
      await pdf.cleanup()
      await pdf.destroy()

      return {
        canvas,
        width: viewport.width / scale,
        height: viewport.height / scale,
        images,
      }
    },
  },
  )
}
