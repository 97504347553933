import { Spinner, ToolbarButton } from '@fluentui/react-components'
import { Print20Filled } from '@fluentui/react-icons'
import { useState } from 'react'
import { track, useEditor } from 'tldraw'
import { useToast } from '../../ui/toast'
import { t } from '../../util/intl/t'
import { usePageRenderResolution } from '../render/resolution'
import { usePage } from '../state/context'
import { printPage } from './page'

export const PrintButton = track((): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false)
  const editor = useEditor()
  const { toast } = useToast()
  const background = usePage().pdf
  const { resolution } = usePageRenderResolution().resolution

  return (
    <ToolbarButton
      appearance="primary"
      icon={busy
        // "tiny" means 16px, which looks better than the usual 20px of
        // button icons.
        ? <Spinner size="extra-tiny" appearance="inverted" />
        : <Print20Filled />}
      onClick={async () => {
        if (busy)
          return toast(t('page.print.in-progress'))
        setBusy(true)
        await printPage({ editor, background, resolution })
        setBusy(false)
      }}
    >
      {t('page.print.button')}
    </ToolbarButton>
  )
})
