import { z } from 'zod'
import { useDataLocal } from '../../util/data/local'

const resolution = z.number().int().min(1).max(16)

const schema = z.object({
  resolution,
})

export type Resolution = z.infer<typeof schema>

const fallback: Resolution = {
  resolution: 8,
}

export function usePageRenderResolution(): {
  resolution: Resolution
  updateResolution: (update: Resolution) => void
} {
  const [resolution, updateResolution] = useDataLocal<Resolution>({
    queryKey: ['resolution'],
    localKey: 'resolution',
    fallback,
    schema,
  })
  return { resolution, updateResolution }
}
