import { Spinner, ToolbarButton } from '@fluentui/react-components'
import { Wand24Filled, Wand24Regular } from '@fluentui/react-icons'
import { useMutation } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { useAttrs } from '../../attr/state/context'
import { usePageRenderResolution } from '../../page/render/resolution'
import { usePage } from '../../page/state/context'
import type { Setting } from '../../setting/setting'
import { useSetting } from '../../setting/setting'
import { useToast } from '../../ui/toast'
import { t } from '../../util/intl/t'
import { PREDICT_MODELS } from '../model/option'
import { PlanExchangeButton } from '../pricing-plan/exchange-button'
import { fetchPredictAll } from './all'

export const PredictFetchButton = track((props: {
  highlight: boolean
}): ReactElement => {
  const { highlight } = props

  const { setting, updateSetting } = useSetting()

  const { attrs, setAttrs } = useAttrs()
  const { toast } = useToast()
  const editor = useEditor()
  const { page, pdf, limit } = usePage()
  const { setLimit } = usePage()
  const { resolution } = usePageRenderResolution().resolution

  const model = PREDICT_MODELS
    .find(m => m.value === setting.predictModel) ?? null

  const mutation = useMutation({
    mutationFn: async () => fetchPredictAll({
      editor,
      model,
      page: page.id,
      pdf: pdf.canvas,
      attrs,
      setAttrs,
      resolution,
    }),
    onSuccess: (result) => {
      const setting: Partial<Setting> = {
        toolbar: 'annot',
        secondary: 'attr',
      }
      if (model === null)
        throw new Error('model is null after fetched')
      if (model.segment !== null)
        setting.segment = model.segment
      updateSetting(setting)

      // "result.aiPredictionRemaining" is a bad design on its own.
      // See the comment at the field for more details.
      // Our temporary workaround is to mark it as Infinity in the reduce's
      // initial value, so if it's still Infinity,
      // we know the request is skipped on FE side,
      // which means we did not even hit server,
      // so we don't have a new limit value.
      if (Number.isFinite(result.aiPredictionRemaining)) {
        setLimit({
          aiPredictionRemaining: result.failed.length > 0
            ? limit.aiPredictionRemaining
            : result.aiPredictionRemaining,
        })
      }

      toast([
        t('predict.fetch.success'),
        `${result.successful}/${result.total}`,
      ].join(' '))

      if (result.failed.length > 0)
        throw new Error(result.failed.join('\n'))
    },
    onError: (error) => {
      toast(error.message)
    },
  })

  if (limit.aiPredictionRemaining > 0) {
    return (
      <ToolbarButton
        appearance={highlight ? 'primary' : 'subtle'}
        icon={mutation.isPending
          // Spinner (16px) looks better when it's smaller than icons (20px).
          // It's always "inverted" because we can only run when there's "some"
          // predict shapes already.
          ? <Spinner size="extra-tiny" appearance="inverted" />
          : highlight ? <Wand24Filled /> : <Wand24Regular />}
        onClick={() => {
          mutation.isPending
            ? toast(t('predict.fetch.pending'))
            : mutation.mutate()
        }}
      >
        {t('predict.fetch.action')}
      </ToolbarButton>
    )
  }
  return (<PlanExchangeButton highlight={highlight} />)
})
