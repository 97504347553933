import { getStrict } from '../../util/web/primitive'
import type { PagePdf } from '../provider/pdf'
import { PRINT_SCALE } from './scale'

/**
 * Print the page's background onto a canvas.
 */
export function printPageBackground(props: {
  print: HTMLCanvasElement
  pdf: PagePdf
  resolution: number
}): void {
  const { print, pdf, resolution } = props

  // Source width and height from the editor canvas.
  // This is the max-scaled values, which are pretty big.
  const [sw, sh] = [pdf.width * resolution, pdf.height * resolution]
  // The requested print scale is based on the original scale of the PDF,
  // so we need to get the original scale first.
  const scale = PRINT_SCALE / resolution
  const [dw, dh] = [sw * scale, sh * scale];

  // It's important to update the destination size here, as the drawing may be
  // cut if it's larger than the canvas.
  [print.width, print.height] = [dw, dh]
  const context = getStrict(print.getContext('2d'))
  context.drawImage(pdf.canvas, 0, 0, sw, sh, 0, 0, dw, dh)
}
